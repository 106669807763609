import { AccountConstants } from 'domains/accounts/constants';
import * as PIX_CONSTANTS from 'domains/pix/constants';
import { TransactionConstants } from 'domains/transactions/constants';
import { UserManagementConstants } from 'domains/userManagement/constants';

import { keyMirror } from 'core/modules/helpers';

import { AppConstants } from './app';
import { AuthConstants } from './auth';
import { RoutesConstants } from './routes';

const { PixConstants } = PIX_CONSTANTS;

export {
  AccountConstants,
  AppConstants,
  AuthConstants,
  RoutesConstants,
  PixConstants,
  TransactionConstants,
  UserManagementConstants
};

export default {
  ...AccountConstants,
  ...AppConstants,
  ...AuthConstants,
  ...RoutesConstants,
  ...PixConstants,
  ...TransactionConstants,
  ...UserManagementConstants,
};

export const ACCOUNT_CLOSING_REASONS = {
  SELF_REQUESTED: 'Self Requested',
  ACCOUNT_TAKEOVER: 'Account Takeover',
  FRAUD: 'Fraud',
  AML: 'AML',
  KYC_FAILURE: 'KYC Failure',
};

export const ACCOUNT_EVENTS = {
  account_creation: 'Account Creation',
  account_status_changed: 'Account Status Changed',
  account_data_changed: 'Account Data Changed',
};

export const ACCOUNT_KYC_STATUS = {
  APPROVED: 'APPROVED',
  MANUAL_ONBOARDING: 'MANUAL_ONBOARDING',
  PROCESSING: 'PROCESSING',
  REPROVED: 'REPROVED',
  REQUIRED: 'REQUIRED',
};

export const ACCOUNT_SUSPENSION_REASONS = {
  ACCOUNT_TAKEOVER: 'Account Takeover',
  AML: 'AML',
  OTHER: 'Other',
};

export const ALLOWED_PERMISSIONS = {
  ACCOUNT_HOLDER: 'accountHolder',
  ACCOUNT: 'account',
  ACCOUNT_CREATION: 'uploadAccountCreationFile',
  ANSWER_PIX_INFRACTIONS: 'answerPixInfractions',
  CARDS: 'cards',
  CLOSE_LEDGER_ACCOUNT: 'closeLedgerAccount',
  REOPEN_LEDGER_ACCOUNT: 'reopenLedgerAccount',
  CREATE_EARNING_REPORT: 'createEarningReport',
  CREATE_MANUAL_TED: 'createManualTed',
  CREATE_SHIPMENT: 'createShipment',
  FRAUD_MARKS: 'fraudMarks',
  IRS_AVAILABLE_DATES: 'availableDates',
  JUDICIAL_BLOCK: 'judicialOperationTransfer',
  ONBOARDING: 'onboarding',
  PIX_ADDRESS_KEYS: 'pixAddressKeys',
  PIX_CHECK_TRANSACTION: 'checkPixTransfer',
  PIX_INFRACTION: 'pixInfraction',
  PIX_INFRACTIONS: 'pixInfractions',
  SHIPMENT_ORDERS: 'shipmentOrders',
  TRANSACTIONS: 'transactions',
  UPDATE_ACCOUNT_DATA: 'updateAccountData',
  UPDATE_ACCOUNT_ONBOARDING_DOC_REQ: 'updateAccountOnboardingDocumentRequested',
  UPDATE_ACCOUNT_STATE: 'makeAccountSuspended',
  UPDATE_CARD: 'updateCardState',
  USER_LOGS: 'userLogs',
  USER_MANAGEMENT_ADD_USER: 'addUser',
  USER_MANAGEMENT_DELETE_USER: 'deleteUser',
  USER_MANAGEMENT_PROFILES: 'profiles',
  USER_MANAGEMENT_UPDATE_USER: 'updateUser',
  USER_MANAGEMENT_USERS: 'users',
  USER_MANAGEMENT_VIEWER: 'viewer',
  VERIFICATION_MANAGEMENT_UPLOAD_INTENT_FILE: 'uploadIntentFile',
};

export const CARD_CANCELLING_REASONS = {
  FRAUD: 'FRAUD',
  HOLDER_REQUEST: 'HOLDER_REQUEST',
  LOST_CARD: 'LOST_CARD',
  STOLEN_CARD: 'STOLEN_CARD',
};

export const CARD_CANCELLING_REASONS_MESSAGES = {
  FRAUD: 'Fraud',
  HOLDER_REQUEST: 'Holder request',
  LOST_CARD: 'Lost card',
  STOLEN_CARD: 'Stolen card',
};

export const CARD_EVENTS = {
  card_activated: 'Card activated',
  card_blocked: 'Card blocked',
  card_canceled: 'Card canceled',
  card_pin_changed: 'Card PIN updated',
  card_unblocked: 'Card unblocked',
};

export const CARD_STATE = {
  ACTIVATED: 'ACTIVATED',
  BLOCKED: 'BLOCKED',
  CANCELLED: 'CANCELLED',
  CREATED: 'CREATED',
  CREATION_FAILED: 'CREATION_FAILED',
  CREATION_REQUESTED: 'CREATION_REQUESTED',
};

export const CARD_POST_ACTIVATED_STATES = [
  CARD_STATE.ACTIVATED,
  CARD_STATE.BLOCKED,
  CARD_STATE.CANCELLED,
];

export const ENVIRONMENTS = {
  DEVELOPMENT: 'dev',
  STAGE: 'stage',
  PRODUCTION: 'prod',
  LIVE: 'live',
};

export const PIX = PIX_CONSTANTS;

export const SHIPMENT_STATUS_TYPE = {
  SHIPMENT: 'shipment',
  PACKAGE: 'package',
};

export const SHIPMENT_STATUS = keyMirror({
  PACKAGE_STATUS_DELIVERED: undefined,
  PACKAGE_STATUS_DELIVERED_TO_PICKUP_POINT: undefined,
  PACKAGE_STATUS_DELIVERY_ATTEMPT_FAILED: undefined,
  PACKAGE_STATUS_DELIVERY_FAILED: undefined,
  PACKAGE_STATUS_DISPATCHED: undefined,
  PACKAGE_STATUS_HUB_SCAN_ARRIVAL: undefined,
  PACKAGE_STATUS_HUB_SCAN_DEPARTURE: undefined,
  PACKAGE_STATUS_IN_TRANSIT: undefined,
  PACKAGE_STATUS_OUT_FOR_DELIVERY: undefined,
  PACKAGE_STATUS_RETURNED_TO_WAREHOUSE: undefined,
  PACKAGE_STATUS_UNSPECIFIED: undefined,
  SHIPMENT_STATUS_CANCELLED: undefined,
  SHIPMENT_STATUS_CREATED: undefined,
  SHIPMENT_STATUS_DISPATCHED: undefined,
  SHIPMENT_STATUS_FAILED: undefined,
  SHIPMENT_STATUS_FINISHED: undefined,
  SHIPMENT_STATUS_PARTIALLY_DISPATCHED: undefined,
  SHIPMENT_STATUS_SAP_PENDING: undefined,
  SHIPMENT_STATUS_SUBMITTED: undefined,
  SHIPMENT_STATUS_UNSPECIFIED: undefined,
});

export const SHIPMENT_STATUS_DEFAULT_MESSAGES = {
  PACKAGE_STATUS_DELIVERED: 'Package delivered',
  PACKAGE_STATUS_DELIVERED_TO_PICKUP_POINT: 'Package delivered to pickup point',
  PACKAGE_STATUS_DELIVERY_ATTEMPT_FAILED: 'Package attempt of delivery failed',
  PACKAGE_STATUS_DELIVERY_FAILED: 'Package delivery failed',
  PACKAGE_STATUS_DISPATCHED: 'Package dispatched',
  PACKAGE_STATUS_HUB_SCAN_ARRIVAL: 'Package checked in on HUB',
  PACKAGE_STATUS_HUB_SCAN_DEPARTURE: 'Package checked out from HUB',
  PACKAGE_STATUS_IN_TRANSIT: 'Package in transit',
  PACKAGE_STATUS_OUT_FOR_DELIVERY: 'Package out for delivery',
  PACKAGE_STATUS_RETURNED_TO_WAREHOUSE: 'Package returned to Warehouse',
  PACKAGE_STATUS_UNSPECIFIED: 'Package status not specified',
  SHIPMENT_STATUS_CANCELLED: 'Order cancelled',
  SHIPMENT_STATUS_CREATED: 'Order created',
  SHIPMENT_STATUS_DISPATCHED: 'Order dispatched to Partner',
  SHIPMENT_STATUS_FAILED: 'Order failed',
  SHIPMENT_STATUS_FINISHED: 'Order finished',
  SHIPMENT_STATUS_PARTIALLY_DISPATCHED: 'Order partially dispatched to Partner',
  SHIPMENT_STATUS_SAP_PENDING: 'Order pending on SAP',
  SHIPMENT_STATUS_SUBMITTED: 'Order informations sended to partner',
  SHIPMENT_STATUS_UNSPECIFIED: 'Order status not specified',
};

export const STATUS = {
  ERROR: 'error',
  IDLE: 'idle',
  READY: 'ready',
  RUNNING: 'running',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const TRANSACTIONS_DICTIONARY = {
  AMOUNT: 'amount',
  ATM_WITHDRAW: 'ATM_WITHDRAW',
  BALANCE_INTEREST: 'BALANCE_INTEREST',
  BANK_EXTERNAL_TRANSFER: 'BANK_EXTERNAL_TRANSFER',
  BANK_EXTERNAL_TRANSFER_IN: 'BANK_EXTERNAL_TRANSFER_IN',
  BANK_EXTERNAL_TRANSFER_OUT: 'BANK_EXTERNAL_TRANSFER_OUT',
  BILL_PAYMENT: 'BILL_PAYMENT',
  BOLETO: 'BOLETO',
  CANCELLATION_ID: 'cancellation_id',
  CASH_IN: 'CASH_IN',
  CASH_OUT: 'CASH_OUT',
  CASH: 'CASH',
  CIP_CASH_IN: 'CIP_CASH_IN',
  CREDIT_CARD_PURCHASE: 'CREDIT_CARD_PURCHASE',
  DEBIT_CARD_PURCHASE: 'DEBIT_CARD_PURCHASE',
  DIRECTION: 'direction',
  END_TO_END_ID: 'endToEndId',
  ID: 'id',
  IS_REFUND: 'isRefund',
  IS_REFUNDABLE: 'isRefundable',
  METADATA: 'metadata',
  MOBILE_TOP_UP: 'MOBILE_TOP_UP',
  PAYMENT_FAILED: 'payment_failed',
  PAYMENT: 'payment',
  PAYOUT: 'PAYOUT',
  PIX_IN: 'PIX_IN',
  PIX_INTERNAL_TRANSFER: 'PIX_INTERNAL_TRANSFER',
  PIX_OUT: 'PIX_OUT',
  PIX_BLOCK: 'PIX_BLOCK',
  POS_NAME: 'pos_name',
  REAL_TIME_PAYOUT: 'REAL_TIME_PAYOUT',
  RECEIPT: 'receipt',
  RECEIPT_DOWNLOAD_FAILED: 'receipt_download_failed',
  SHORT_DESCRIPTION: 'shortDescription',
  SUMUP_PAYMENTS_TRANSFER: 'SUMUP_PAYMENTS_TRANSFER',
  SUMUP_SALE: 'sumup_sale',
  TAGS: 'tags',
  TED_IN: 'TED_IN',
  TED_OUT_COST: 'TED_OUT_COST',
  TED_OUT: 'TED_OUT',
  TRANSFER_ID: 'transferId',
  TRANSFER_IN: 'TRANSFER_IN',
  TRANSFER_OUT: 'TRANSFER_OUT',
  TYPE: 'type',
  UTC_DATE_TIME: 'utcDatetime',
  UTC_DATE: 'utcDate',
  UTC_TIME: 'utcTime',
  WITHDRAW: 'WITHDRAW',
  SECURED_CREDIT_CARD_LIMIT: 'SECURED_CREDIT_CARD_LIMIT',
  CARD_STATEMENT_PAYMENT: 'CARD_STATEMENT_PAYMENT',
  PROVISIONAL_CREDIT_DEBIT_CARD_PURCHASE: 'PROVISIONAL_CREDIT_DEBIT_CARD_PURCHASE',
  PROVISIONAL_CREDIT_DEBIT_WITHDRAW: 'PROVISIONAL_CREDIT_DEBIT_WITHDRAW',
  PROVISIONAL_CREDIT_DEBIT_ATM_WITHDRAW_FEE: 'PROVISIONAL_CREDIT_DEBIT_ATM_WITHDRAW_FEE',
  OPERATIONAL_LOSS_DEBIT: 'OPERATIONAL_LOSS_DEBIT',
};

export const USER_LOGS_EVENTS = {
  DEFAULT: 'Event not identified',
  ...CARD_EVENTS,
  ...ACCOUNT_EVENTS,
};

export const USER_LOG_REASONS = {
  DEFAULT: 'Reason not identified',
  ...ACCOUNT_CLOSING_REASONS,
  ...ACCOUNT_SUSPENSION_REASONS,
  ...CARD_CANCELLING_REASONS_MESSAGES,
};

export const SUMUP_TRACKING_PAGE_BASE_URL = 'https://tracking.sumup.com/';
