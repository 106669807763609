import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { datadogLogs } from '@datadog/browser-logs';

import { client, getRequestOptions } from 'core/modules/resources';
import { parseResponseError } from 'core/modules/client';
import { parserKycFiles } from 'core/modules/parsers';

import { AccountConstants } from '../constants';

import {
  queryGetAccountHolder,
  queryGetAccountOcr,
  queryGetCompanyKycProcess,
  queryGetEarningReportAvailableYears,
  queryGetKYCBackgroundCheck,
  queryGetKYCFacematch,
  queryGetKYCFIle,
  queryGetKYCPepAndSanctions,
  queryGetShipmentOrders,
  queryGetTransactions,
  queryGetUserLogs,
} from '../graphql/queries';

import {
  mutationCloseLedgerAccount,
  mutationReopenLedgerAccount,
  mutationCreateStatement,
  mutationEarningReport,
  mutationJudicialBlock,
  mutationMakeAccountKycRequired,
  mutationMakeAccountSuspended,
  mutationMakeAccountUnlimited,
  mutationManualTED,
  mutationRemoveAccountSuspension,
  mutationUpdateAccountData,
  mutationUpdateOnboardingDocRequest,
} from '../graphql/mutations';
import { backgroundToKycChecks, companyToKycChecks } from './utils';

export function* closeLedgerAccount({ payload }) {
  try {
    const response = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationCloseLedgerAccount(payload.accountNumber, payload.reason),
      method: 'POST',
      operationName: 'closeLedgerAccount',
    });

    const successful = response?.data?.closeLedgerAccount?.successful;

    if (!successful) {
      const [ error ] = response?.data?.closeLedgerAccount?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_CLOSE_LEDGER_ACCOUNT_SUCCESS,
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    datadogLogs.logger.info(message, { name: 'closeLedgerAccount', status });

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_CLOSE_LEDGER_ACCOUNT_FAILURE,
      payload: { message: error, status },
    });
  }
}

export function* reopenLedgerAccount({ payload }) {
  try {
    const response = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationReopenLedgerAccount(payload.nationalId),
      method: 'POST',
      operationName: 'reopenLedgerAccount',
    });

    const successful = response?.data?.reopenLedgerAccount?.successful;

    if (!successful) {
      const [error] = response?.data?.reopenLedgerAccount?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_REOPEN_LEDGER_ACCOUNT_SUCCESS,
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    datadogLogs.logger.info(message, { name: 'reopenLedgerAccount', status });

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_REOPEN_LEDGER_ACCOUNT_FAILURE,
      payload: { message: error, status },
    });
  }
}

export function* createEarningReport({ payload }) {
  try {
    const response = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationEarningReport(payload.mid, payload.year),
      method: 'POST',
      operationName: 'createEarningReport',
    });

    const successful = response?.data?.createEarningReport?.successful;

    if (!successful) {
      const [ error ] = response?.data?.createEarningReport?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: AccountConstants.ACCOUNT_EARNING_REPORT_SUCCESS,
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    datadogLogs.logger.info(message, { name: 'createEarningReport', status });

    yield put({
      type: AccountConstants.ACCOUNT_EARNING_REPORT_FAILURE,
      payload: { message: error, status },
    });
  }
}

export function* judicialBlock({ payload }) {
  try {
    const response = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationJudicialBlock(payload),
      method: 'POST',
      operationName: 'judicialBlock',
    });

    const successful = response?.data?.judicialOperationTransfer?.successful;

    if (!successful) {
      const [ error ] = response?.data?.judicialOperationTransfer?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: AccountConstants.ACCOUNT_JUDICIAL_BLOCK_SUCCESS,
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    datadogLogs.logger.info(message, { name: 'judicialBlock', status });

    yield put({
      type: AccountConstants.ACCOUNT_JUDICIAL_BLOCK_FAILURE,
      payload: { message: error, status },
    });
  }
}

export function* createManualTED({ payload }) {
  try {
    const { data } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationManualTED(payload.merchantCode, payload.bankNumber, payload.branchNumber, payload.accountNumber, Boolean(payload.useRepresentative)),
      method: 'POST',
      operationName: 'createManualTED',
    });

    const successful = data?.createManualTed?.successful;

    if (!successful) {
      const [ error ] = data?.createManualTed?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: AccountConstants.ACCOUNT_OPERATION_MANUAL_TED_SUCCESS,
    });
  }
  catch (error) {
    yield put({
      type: AccountConstants.ACCOUNT_OPERATION_MANUAL_TED_FAILURE,
      payload: { message: error.message },
    });
  }
}

export function* createStatement({ payload }) {
  try {
    const { data } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationCreateStatement(payload),
      method: 'POST',
      operationName: 'createStatement',
    });

    const successful = data?.createManualStatement?.successful;

    if (!successful) {
      const [ error ] = data?.createManualStatement?.messages;
      throw new Error(error.message);
    }

    const { fileContent, filename } = data?.createManualStatement?.result;

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_CREATE_STATEMENT_SUCCESS,
      payload: {
        fileContent,
        filename,
      },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    datadogLogs.logger.info(message, { name: 'createStatement', status });

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_CREATE_STATEMENT_FAILURE,
      payload: { message: error, status },
    });
  }
}

export function* getAccountHolder({ payload }) {
  try {
    const { data, errors } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield queryGetAccountHolder.getAccountHolder(payload.kind, payload.value),
      method: 'POST',
      operationName: 'getAccountHolder',
    });

    if (!data?.accountHolder) {
      throw new Error(errors[0].message);
    }

    if (data?.accountHolder?.personType === 'PJ') {
      yield put({
        type: AccountConstants.ACCOUNT_HOLDER_PJ_FETCH_REQUEST,
        payload: {
          kind: payload.kind,
          value: payload.value,
        },
      });
    }

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_FETCH_SUCCESS,
      payload: { data: data?.accountHolder },
    });

    yield put({
      type: AccountConstants.ACCOUNT_TRANSACTIONS_FETCH_REQUEST,
      payload: { id: data?.accountHolder.nationalId },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    datadogLogs.logger.info(message, { name: 'getAccountHolder', status });

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_FETCH_FAILURE,
      payload: { message: error, status },
    });
  }
}

export function* getAccountHolderPJ({ payload }) {
  try {
    const { data, errors } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield queryGetAccountHolder.getAccountHolderPJ(payload.kind, payload.value),
      method: 'POST',
      operationName: 'getAccountHolderPJ',
    });

    if (!data?.accountHolder) {
      throw new Error(errors[0].message);
    }

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_PJ_FETCH_SUCCESS,
      payload: { data: data?.accountHolder },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_PJ_FETCH_FAILURE,
      payload: { message: error, status },
    });
  }
}

export function* getEarningReportAvailableYears({ payload }) {
  try {
    const { data }  = yield call(client, {
      ...yield getRequestOptions(),
      ...yield queryGetEarningReportAvailableYears(payload.merchantCode),
      method: 'POST',
      operationName: 'getEarningReportAvailableYears',
    });

    if (!data?.getEarningReportAvailableYears) {
      const [ error ] = data?.getEarningReportAvailableYears?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: AccountConstants.ACCOUNT_EARNING_REPORT_AVAILABLE_YEARS_SUCCESS,
      payload: { years: data?.getEarningReportAvailableYears.availableYears },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AccountConstants.ACCOUNT_EARNING_REPORT_AVAILABLE_YEARS_FAILURE,
      payload: {
        message: error.message,
      },
    });

    datadogLogs.logger.info(error.message, { name: 'getEarningReportAvailableYears', status });
  }
}

export function* getShipmentOrders({ payload }) {
  try {
    const { data, errors } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield queryGetShipmentOrders(payload.sumupMerchantCode),
      method: 'POST',
      operationName: 'getShipmentOrders',
    });

    if (!data?.shipmentOrders) {
      throw new Error(errors[0].message);
    }

    yield put({
      type: AccountConstants.ACCOUNT_SHIPMENT_ORDERS_SUCCESS,
      payload: { data: data?.shipmentOrders },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AccountConstants.ACCOUNT_SHIPMENT_ORDERS_FAILURE,
      payload: { message: error, status },
    });
  }
}

export function* getTransactions({ payload }) {
  const limit = 60;
  const { filter, id, pageNumber } = payload;

  try {
    const { data, errors } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield queryGetTransactions(filter, limit, id, pageNumber),
      method: 'POST',
      operationName: 'getTransactions',
    });

    if (!data?.transactionsConnection) {
      throw new Error(errors[0].message);
    }

    yield put({
      type: AccountConstants.ACCOUNT_TRANSACTIONS_SUCCESS,
      payload: {
        data: data.transactionsConnection,
        filters: payload?.filter,
      },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);
    yield put({
      type: AccountConstants.ACCOUNT_TRANSACTIONS_FAILURE,
      payload: { message: error },
    });
  }
}

export function* getUserLogs({ payload }) {
  try {
    const { data, errors } = yield call(client, {
      ...yield getRequestOptions(),
      ...yield queryGetUserLogs(payload.accountId),
      method: 'POST',
      operationName: 'getUserLogs',
    });

    if (!data?.userLogs) {
      throw new Error(errors[0].message);
    }
    yield put({
      type: AccountConstants.ACCOUNT_USER_LOGS_SUCCESS,
      payload: { data: data.userLogs },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);
    yield put({
      type: AccountConstants.ACCOUNT_USER_LOGS_FAILURE,
      payload: { message: error },
    });
  }
}

export function* makeAccountUnlimited({ payload }) {
  try {
    const { data }  = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationMakeAccountUnlimited(payload.nationalId),
      method: 'POST',
      operationName: 'makeAccountUnlimited',
    });

    const successful = data?.makeAccountUnlimited?.successful;

    if (!successful) {
      const [ error ] = data?.makeAccountUnlimited?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_MAKE_ACCOUNT_UNLIMITED_SUCCESS,
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_MAKE_ACCOUNT_UNLIMITED_FAILURE,
      payload: {
        message: error.message,
      },
    });

    datadogLogs.logger.info(error.message, { name: 'makeAccountUnlimited', status });
  }
}

export function* makeAccountKycRequired({ payload }) {
  try {
    const { data }  = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationMakeAccountKycRequired(payload.accountNumber),
      method: 'POST',
      operationName: 'makeAccountKycRequired',
    });

    const successful = data?.makeAccountKycRequired?.successful;

    if (!successful) {
      const [ error ] = data?.makeAccountKycRequired?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: AccountConstants.KYC_MAKE_ACCOUNT_KYC_REQUIRED_SUCCESS,
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AccountConstants.KYC_MAKE_ACCOUNT_KYC_REQUIRED_FAILURE,
      payload: {
        message: error.message,
      },
    });

    datadogLogs.logger.info(error.message, { name: 'makeAccountKycRequired', status });
  }
}

export function* removeAccountSuspension({ payload }) {
  try {
    const { data }  = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationRemoveAccountSuspension(payload.nationalId),
      method: 'POST',
      operationName: 'removeAccountSuspension',
    });

    const successful = data?.removeAccountSuspension?.successful;

    if (!successful) {
      const [ error ] = data?.removeAccountSuspension?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_REMOVE_ACCOUNT_SUSPENSION_SUCCESS,
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_REMOVE_ACCOUNT_SUSPENSION_FAILURE,
      payload: {
        message: error.message,
      },
    });

    datadogLogs.logger.info(error.message, { name: 'removeAccountSuspension', status });
  }
}

export function* makeAccountSuspended({ payload }) {
  try {
    const { data }  = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationMakeAccountSuspended(payload.nationalId, payload.reason, payload.state),
      method: 'POST',
      operationName: 'makeAccountSuspended',
    });

    const successful = data?.makeAccountSuspended?.successful;

    if (!successful) {
      const [ error ] = data?.makeAccountSuspended?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_MAKE_ACCOUNT_SUSPENDED_SUCCESS,
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_MAKE_ACCOUNT_SUSPENDED_FAILURE,
      payload: {
        message: error.message,
      },
    });

    datadogLogs.logger.info(error.message, { name: 'makeAccountSuspended', status });
  }
}

export function* updateAccountHolderData({ payload }) {
  try {
    const { data }  = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationUpdateAccountData(payload.accountData),
      method: 'POST',
      operationName: 'updateAccountData',
    });

    const successful = data?.updateAccountData?.successful;

    if (!successful) {
      const [ error ] = data?.updateAccountData?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_UPDATE_DATA_SUCCESS,
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_UPDATE_DATA_FAILURE,
      payload: { message: error.message },
    });

    datadogLogs.logger.info(error.message, { name: 'updateAccountHolderData', status });
  }
}

export function* updateOnboardingDocRequest({ payload }) {
  try {
    const { data }  = yield call(client, {
      ...yield getRequestOptions(),
      ...yield mutationUpdateOnboardingDocRequest(payload.accountNumber, payload.status),
      method: 'POST',
      operationName: 'updateOnboardingDocRequest',
    });

    const successful = data?.updateAccountOnboardingDocumentRequested?.successful;

    if (!successful) {
      const [ error ] = data?.updateAccountOnboardingDocumentRequested?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_UPDATE_ONBOARDING_DOC_REQ_SUCCESS,
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AccountConstants.ACCOUNT_HOLDER_UPDATE_ONBOARDING_DOC_REQ_FAILURE,
      payload: {
        message: error.message,
      },
    });

    datadogLogs.logger.info(error.message, { name: 'updateOnboardingDocRequest', status });
  }
}

export function* kycBackgroundCheck({ payload }) {
  try {
    const { data }  = yield call(client, {
      ...yield getRequestOptions(),
      ...yield queryGetKYCBackgroundCheck(payload.accountNumber),
      method: 'POST',
      operationName: 'kycBackgroundCheck',
    });

    if (!data?.accountKycBackgroundProcess) {
      const [ error ] = data?.accountKycBackgroundProcess?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: AccountConstants.KYC_BACKGROUND_CHECK_SUCCESS,
      payload: {
        data: data?.accountKycBackgroundProcess,
        checks: backgroundToKycChecks(data?.accountKycBackgroundProcess),
      },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AccountConstants.KYC_BACKGROUND_CHECK_FAILURE,
      payload: {
        message: error.message,
      },
    });

    datadogLogs.logger.info(error.message, { name: 'kycBackgroundCheck', status });
  }
}

export function* kycCompanyProcess({ payload }) {
  try {
    const { data }  = yield call(client, {
      ...yield getRequestOptions(),
      ...yield queryGetCompanyKycProcess(payload.accountNumber),
      method: 'POST',
      operationName: 'kycCompanyProcess',
    });

    if (!data?.companyKycProcess) {
      const [ error ] = data?.companyKycProcess?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: AccountConstants.KYC_COMPANY_PROCESS_SUCCESS,
      payload: {
        data: data?.companyKycProcess,
        checks: companyToKycChecks(data?.companyKycProcess?.companyIdentity?.details),
      },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AccountConstants.KYC_COMPANY_PROCESS_FAILURE,
      payload: {
        message: error.message,
      },
    });

    datadogLogs.logger.info(error.message, { name: 'kycCompanyProcess', status });
  }
}

export function* accountOcr({ payload }) {
  try {
    const { data }  = yield call(client, {
      ...yield getRequestOptions(),
      ...yield queryGetAccountOcr(payload.accountNumber),
      method: 'POST',
      operationName: 'accountOcr',
    });

    if (!data?.accountOcr) {
      const [ error ] = data?.accountOcr?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: AccountConstants.ACCOUNT_OCR_SUCCESS,
      payload: { data: data?.accountOcr },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AccountConstants.ACCOUNT_OCR_FAILURE,
      payload: {
        message: error.message,
      },
    });

    datadogLogs.logger.info(error.message, { name: 'accountOcr', status });
  }
}

export function* kycFacematch({ payload }) {
  try {
    const { data }  = yield call(client, {
      ...yield getRequestOptions(),
      ...yield queryGetKYCFacematch(payload.accountNumber),
      method: 'POST',
      operationName: 'kycFacematch',
    });

    if (!data?.accountKycFaceMatchProcess) {
      const [ error ] = data?.accountKycFaceMatchProcess?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: AccountConstants.KYC_FACEMATCH_SUCCESS,
      payload: { data: data?.accountKycFaceMatchProcess },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AccountConstants.KYC_FACEMATCH_FAILURE,
      payload: {
        message: error.message,
      },
    });

    datadogLogs.logger.info(error.message, { name: 'kycFacematch', status });
  }
}

export function* kycPepAndSanctions({ payload }) {
  try {
    const { data }  = yield call(client, {
      ...yield getRequestOptions(),
      ...yield queryGetKYCPepAndSanctions(payload.accountNumber),
      method: 'POST',
      operationName: 'kycPepAndSanctions',
    });

    if (!data?.accountKycPepAndSanctionsProcess) {
      const [ error ] = data?.accountKycPepAndSanctionsProcess?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: AccountConstants.KYC_PEP_AND_SANCTIONS_SUCCESS,
      payload: { data: data?.accountKycPepAndSanctionsProcess },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AccountConstants.KYC_PEP_AND_SANCTIONS_FAILURE,
      payload: {
        message: error.message,
      },
    });

    datadogLogs.logger.info(error.message, { name: 'kycPepAndSanctions', status });
  }
}

export function* kycFacematchFile({ payload }) {
  try {
    const { data }  = yield call(client, {
      ...yield getRequestOptions(),
      ...yield queryGetKYCFIle(payload.idFile),
      method: 'POST',
      operationName: 'kycFacematchFile',
    });

    const kycFiles = data?.accountKycFiles;

    if (!kycFiles) {
      const [ error ] = kycFiles?.messages;
      throw new Error(error.message);
    }

    yield put({
      type: AccountConstants.KYC_FACEMATCH_FILE_SUCCESS,
      payload: {
        data: parserKycFiles(data?.accountKycFiles),
      },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AccountConstants.KYC_FACEMATCH_FILE_FAILURE,
      payload: {
        message: error.message,
      },
    });

    datadogLogs.logger.info(error.message, { name: 'kycFacematchFile', status });
  }
}

export default function* root() {
  yield all([
    takeLatest(AccountConstants.ACCOUNT_EARNING_REPORT_REQUEST, createEarningReport),
    takeLatest(AccountConstants.ACCOUNT_JUDICIAL_BLOCK_REQUEST, judicialBlock),
    takeLatest(AccountConstants.ACCOUNT_EARNING_REPORT_AVAILABLE_YEARS_REQUEST, getEarningReportAvailableYears),
    takeLatest(AccountConstants.ACCOUNT_HOLDER_CREATE_STATEMENT_REQUEST, createStatement),
    takeLatest(AccountConstants.ACCOUNT_HOLDER_CLOSE_LEDGER_ACCOUNT_REQUEST, closeLedgerAccount),
    takeLatest(AccountConstants.ACCOUNT_HOLDER_REOPEN_LEDGER_ACCOUNT_REQUEST, reopenLedgerAccount),
    takeLatest(AccountConstants.ACCOUNT_HOLDER_MAKE_ACCOUNT_SUSPENDED_REQUEST, makeAccountSuspended),
    takeLatest(AccountConstants.ACCOUNT_HOLDER_FETCH_REQUEST, getAccountHolder),
    takeLatest(AccountConstants.ACCOUNT_HOLDER_PJ_FETCH_REQUEST, getAccountHolderPJ),
    takeLatest(AccountConstants.ACCOUNT_HOLDER_MAKE_ACCOUNT_UNLIMITED_REQUEST, makeAccountUnlimited),
    takeLatest(AccountConstants.ACCOUNT_HOLDER_UPDATE_ONBOARDING_DOC_REQ_REQUEST, updateOnboardingDocRequest),
    takeLatest(AccountConstants.ACCOUNT_HOLDER_UPDATE_DATA_REQUEST, updateAccountHolderData),
    takeLatest(AccountConstants.ACCOUNT_OPERATION_MANUAL_TED_REQUEST, createManualTED),
    takeLatest(AccountConstants.ACCOUNT_HOLDER_REMOVE_ACCOUNT_SUSPENSION_REQUEST, removeAccountSuspension),
    takeLatest(AccountConstants.ACCOUNT_SHIPMENT_ORDERS_REQUEST, getShipmentOrders),
    takeLatest(AccountConstants.ACCOUNT_TRANSACTIONS_FETCH_REQUEST, getTransactions),
    takeLatest(AccountConstants.ACCOUNT_USER_LOGS_REQUEST, getUserLogs),
    takeLatest(AccountConstants.ACCOUNT_OCR_REQUEST, accountOcr),
    takeLatest(AccountConstants.KYC_BACKGROUND_CHECK_REQUEST, kycBackgroundCheck),
    takeLatest(AccountConstants.KYC_PEP_AND_SANCTIONS_REQUEST, kycPepAndSanctions),
    takeLatest(AccountConstants.KYC_FACEMATCH_REQUEST, kycFacematch),
    takeLatest(AccountConstants.KYC_MAKE_ACCOUNT_KYC_REQUIRED_REQUEST, makeAccountKycRequired),
    takeEvery(AccountConstants.KYC_FACEMATCH_FILE_REQUEST, kycFacematchFile),
    takeEvery(AccountConstants.KYC_COMPANY_PROCESS_REQUEST, kycCompanyProcess),
  ]);
};
