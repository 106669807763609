import { all, fork } from 'redux-saga/effects';

import accounts from 'domains/accounts/sagas';
import cards from 'domains/cards/sagas';
import finance from 'domains/finance/sagas';
import pix from 'domains/pix/sagas';
import transaction from 'domains/transactions/sagas';
import userManagement from 'domains/userManagement/sagas';
import accountCreation from 'domains/accountCreation/sagas';
import verificationManagement from 'domains/verificationManagement/sagas';

import auth from './auth';

export default function* root() {
  yield all([
    fork(accounts),
    fork(cards),
    fork(userManagement),
    fork(finance),
    fork(pix),
    fork(auth),
    fork(transaction),
    fork(accountCreation),
    fork(verificationManagement),
  ]);
}
