import { defineMessages } from 'react-intl';

export const sidebarMessages = defineMessages({
  account_holder: {
    id: 'sidebar.accountHolder',
    defaultMessage: 'Account Holder',
  },
  finance: {
    id: 'sidebar.finance',
    defaultMessage: 'Finance',
  },
  user_management: {
    id: 'sidebar.userManagement',
    defaultMessage: 'User Management',
  },
  account_management: {
    id: 'sidebar.account_management',
    defaultMessage: 'Account Management',
  },
  account_creation: {
    id: 'sidebar.accountCreation',
    defaultMessage: 'Account Creation',
  },
  verification_management: {
    id: 'sidebar.verificationManagement',
    defaultMessage: 'Verification Management',
  },
  pix: {
    id: 'sidebar.pix',
    defaultMessage: 'Pix',
  },
  infractions: {
    id: 'sidebar.infractions',
    defaultMessage: 'Infraction Reports',
  },
  create_infraction: {
    id: 'sidebar.create',
    defaultMessage: 'Create Infraction Report',
  },
  check_transaction: {
    id: 'sidebar.checkTransaction',
    defaultMessage: 'Check Pix Transaction',
  },
  refunds: {
    id: 'sidebar.refunds',
    defaultMessage: 'Refund',
  },
});

export const fraudMessages = defineMessages({
  ideological_falsehood: {
    id: 'pix.fraudMark.ideologicalFalsehood',
    defaultMessage: 'Ideological Falsehood',
  },
  mule_account: {
    id: 'pix.fraudMark.muleAccount',
    defaultMessage: 'Mule Account',
  },
  scammer_account: {
    id: 'pix.fraudMark.scammerAccount',
    defaultMessage: 'Scammer Account',
  },
  other: {
    id: 'pix.fraudMark.other',
    defaultMessage: 'Other',
  },
  fraud_type_button: {
    id: 'pix.fraudMark.fraudMarkButton',
    defaultMessage: 'Select',
  },
});

export const reasonsMessages = defineMessages({
  account_takeover: {
    id: 'closeAccount.reason.account_takeover',
    defaultMessage: 'ATO case (account Takeover)',
  },
  aml: {
    id: 'closeAccount.reason.aml',
    defaultMessage: 'Closing by AML',
  },
  aml_suspend: {
    id: 'suspendAccount.reason.aml_suspend',
    defaultMessage: 'AML case',
  },
  fraud: {
    id: 'closeAccount.reason.fraud',
    defaultMessage: 'Closure for fraud',
  },
  kyc_failure: {
    id: 'closeAccount.reason.kyc_failure',
    defaultMessage: 'KYC failure',
  },
  self_requested: {
    id: 'closeAccount.reason.self_requested',
    defaultMessage: 'Request made by an account holder',
  },
  others: {
    id: 'suspendAccount.reason.others',
    defaultMessage: 'Others',
  },
});

export const dialogMessages = defineMessages({
  confirm: {
    id: 'dialog.confirm',
    defaultMessage: 'Confirm',
  },
  answerInfractionConfirm: {
    id: 'pix.infraction.answermodal.confirmbutton',
    defaultMessage: 'Confirmar envio',
  },
});

export const pixInfractionStatusMessages = defineMessages({
  answered: {
    id: 'pix.infraction.status.ACCEPTED',
    defaultMessage: 'Answered',
  },
  cancelled: {
    id: 'pix.infraction.status.CANCELLED',
    defaultMessage: 'Cancelled',
  },
  pending_answer: {
    id: 'pix.infraction.status.RECEIVED',
    defaultMessage: 'Pending answer',
  },
});

export const locales = ['en', 'es', 'pt'];
